import { jsxs, jsx } from 'react/jsx-runtime.js';
import DownloadIcon from '@jetbrains/icons/download.js';
import WarningIcon from '@jetbrains/icons/warning.js';
import Icon from '@jetbrains/ring-ui-built/components/icon/icon.js';
import { Directions } from '@jetbrains/ring-ui-built/components/popup/popup.consts.js';
import Popup from '@jetbrains/ring-ui-built/components/popup/popup.js';
import { Button } from '@jetbrains/ring-ui-built/components/button/button.js';
import cn from 'classnames';
import 'react';
import 'uuid';
import { useOpenCloseState } from '../../hooks/useOpenCloseState.js';
import '@jetbrains/ring-ui-built/components/global/theme.js';
import '../Theme/ThemeContext.js';
import '../ApplicationMenu/ApplicationMenuContext.js';
import '../../utils/applicationMenu/applicationMenu.js';
import '@babel/runtime/helpers/defineProperty';
import { DOWNLOAD_SUBMENU_ID } from '../ApplicationMenu/constants.js';
import { useSubscribeSubMenuItems } from '../ApplicationMenu/useSubscribeSubMenuItems.js';
import { SmallText } from '../Typography/Typography.js';
import styles from './DownloadSelectButton.module.css.js';

const WARNING_ICON_SIZE = 10;
const DownloadSelectButton = () => {
  const [isOpen, open, close] = useOpenCloseState(false);
  const menuItems = useSubscribeSubMenuItems(DOWNLOAD_SUBMENU_ID);
  const handleItemClick = targetHandler => {
    close();
    if (targetHandler) {
      targetHandler();
    }
  };
  return jsxs("div", {
    children: [jsx(Button, {
      onClick: open,
      className: styles.downloadButton,
      children: jsx(Icon, {
        className: cn(styles.downloadButtonIcon, 'scalableIcon'),
        suppressSizeWarning: true,
        glyph: DownloadIcon
      })
    }), jsx(Popup, {
      directions: [Directions.BOTTOM_LEFT],
      hidden: !isOpen,
      onEscPress: close,
      onOutsideClick: close,
      className: styles.popup,
      children: menuItems.map(_ref => {
        let {
          id,
          click,
          label,
          description,
          htmlProps
        } = _ref;
        return jsxs("button", {
          className: styles.popupItem,
          onClick: () => handleItemClick(click),
          ...htmlProps,
          children: [label, description && jsxs("div", {
            className: styles.errorDescriptionContainer,
            children: [jsx(Icon, {
              size: WARNING_ICON_SIZE,
              className: cn(styles.errorDescriptionIcon, 'scalableIcon'),
              glyph: WarningIcon
            }), jsx(SmallText, {
              className: styles.errorDescription,
              children: description
            })]
          })]
        }, id);
      })
    })]
  });
};

export { DownloadSelectButton };
